import React from 'react';

interface ContentVersionContextState {
  /**
   * Current content version
   */
  contentVersion: number;

  /**
   * Set the current content version
   */
  setContentVersion: React.Dispatch<React.SetStateAction<number>>;
}

export const ContentVersionContext = React.createContext<ContentVersionContextState>({
  contentVersion: 0,
  setContentVersion: () => {},
});

// eslint-disable-next-line @typescript-eslint/no-empty-object-type
export interface ContentVersionContextProviderProps {}

/**
 * The purpose of this context is to connect content-service updates that may happen
 * when an author edits content in FACE-UI with (re-)loading of external feature apps
 * that may consume the updated content but won't get notified out of the box.
 *
 * To achieve this, `contentVersion` is increased on content updates in FeatureApp.tsx so
 * that a re-rendering of FA loading via <FeatureAppLoader> can be triggered by using
 * `contentVersion` in the wrapping component.
 */
export const ContentVersionContextProvider = ({
  children,
}: React.PropsWithChildren<ContentVersionContextProviderProps>) => {
  const [contentVersion, setContentVersion] = React.useState<number>(0);

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <ContentVersionContext.Provider value={{ contentVersion, setContentVersion }}>
      {children}
    </ContentVersionContext.Provider>
  );
};
