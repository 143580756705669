/**
 * This is the entry point for Feature Hub App integration
 */

import React from 'react';
import { FeatureAppDefinition, FeatureAppEnvironment, FeatureServices } from '@feature-hub/core';
import { ReactFeatureApp } from '@feature-hub/react';
import type { Logger } from '@feature-hub/logger';
import { UniversalEditorProvider } from '@oneaudi/falcon-tools';
import { FootnoteContextProvider } from '@oneaudi/feature-app-utils';

import { ContentServiceV1 } from '@oneaudi/content-service';
import { AudiFootnoteRefernceServiceScopeManagerInterfaceV3 } from '@oneaudi/footnote-reference-service';
import { TrackingServiceV2 } from '@oneaudi/audi-tracking-service';
import { RenderModeServiceV1 } from '@oneaudi/render-mode-service';

import App from './FeatureApp';
import { ContextProvider } from './Context';
import { ContentVersionContextProvider } from './ContentVersionContext';

export interface FeatureServiceDependencies extends FeatureServices {
  readonly 's2:logger'?: Logger;
  readonly 'audi-content-service': ContentServiceV1;
  readonly 'audi-footnote-reference-service': AudiFootnoteRefernceServiceScopeManagerInterfaceV3;
  readonly 'audi-tracking-service'?: TrackingServiceV2;
  readonly 'audi-render-mode-service': RenderModeServiceV1;
}

const featureAppDefinition: FeatureAppDefinition<ReactFeatureApp, FeatureServiceDependencies> = {
  dependencies: {
    featureServices: {
      's2:logger': '^1.0.0',
      'audi-content-service': '^1.0.0',
    },
    externals: {
      react: '*',
      'styled-components': '*',
    },
  },
  optionalDependencies: {
    featureServices: {
      'audi-footnote-reference-service': '^3.0.0',
      'audi-tracking-service': '^2.0.0',
      'audi-render-mode-service': '^1.0.0',
    },
  },

  create: ({
    featureServices,
    featureAppId,
    featureAppName,
  }: FeatureAppEnvironment<FeatureServiceDependencies, void>) => {
    const loggerService = featureServices['s2:logger'];
    loggerService?.info('Feature App created.');

    const contentService = featureServices['audi-content-service'];

    const footnoteReferenceServiceScopeManager = featureServices['audi-footnote-reference-service'];
    const footnoteReferenceService =
      footnoteReferenceServiceScopeManager?.getDefaultScopeRefService();

    const trackingService = featureServices['audi-tracking-service'] as TrackingServiceV2;
    if (trackingService) {
      trackingService.featureAppName = __FEATURE_APP_NAME__;
    }

    const renderModeService = featureServices['audi-render-mode-service'];

    return {
      render: () => {
        return (
          <UniversalEditorProvider
            contentService={contentService}
            renderModeService={renderModeService}
          >
            <FootnoteContextProvider footnoteReferenceService={footnoteReferenceService}>
              <div data-fefa-custom-id={footnoteReferenceService.getConsumerId()}>
                <ContextProvider
                  featureAppEnv={{ featureAppId, featureAppName }}
                  trackingService={trackingService}
                  renderModeService={renderModeService}
                >
                  <ContentVersionContextProvider>
                    <App contentService={contentService} />
                  </ContentVersionContextProvider>
                </ContextProvider>
              </div>
            </FootnoteContextProvider>
          </UniversalEditorProvider>
        );
      },
    };
  },
};

export default featureAppDefinition;
