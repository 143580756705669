import { ContentFragment } from '@oneaudi/falcon-tools';

import { Content as FAModularEditorialContent } from './types-fa-modular-editorial';
import { BasicTeaserContent as ContentModuleFABasicTeaserContent } from './types-fa-basic-teaser';
import { EditorialColumnsFACE as ContentModuleFAEditorialColumnsContent } from './types-fa-editorial-columns';

export type ThemeType = 'Dark' | 'Light';

export const FA_LOCATOR_ID = `fa-foldout`;
export const FA_LOCATOR = `[data-test-id=${FA_LOCATOR_ID}]`;

export const CONTENT_TYPE_FA_MODULAR_EDITORIAL = 'fa-modular-editorial';
export const CONTENT_TYPE_FA_BASIC_TEASER = 'fa-basic-teaser';
export const CONTENT_TYPE_FA_EDITORIAL_COLUMNS = 'fa-editorial-columns';
export const CONTENT_TYPE_TEST = 'test-content-type';
export const CONTENT_TYPE_CF = 'cf-content-type';

export type ContentModuleType =
  | ContentModuleFAModularEditorial
  | ContentModuleFABasicTeaser
  | ContentModuleFAEditorialColumns
  | ContentModuleTest
  | ContentModuleCF;

export interface ContentModuleFAModularEditorial extends FAModularEditorialContent {
  contentType?: typeof CONTENT_TYPE_FA_MODULAR_EDITORIAL;
}

export interface ContentModuleFABasicTeaser extends ContentModuleFABasicTeaserContent {
  contentType?: typeof CONTENT_TYPE_FA_BASIC_TEASER;
}
export interface ContentModuleFAEditorialColumns extends ContentModuleFAEditorialColumnsContent {
  contentType?: typeof CONTENT_TYPE_FA_EDITORIAL_COLUMNS;
}

export interface ContentModuleCF extends ContentFragment {
  contentType?: typeof CONTENT_TYPE_CF;
}

export interface ContentModuleTest {
  contentType?: typeof CONTENT_TYPE_TEST;
}

export interface EditorJsonContent {
  __type?: 'editor.json'; // This will in reality never be set, but it makes mapping easier
  themeOption?: {
    theme?: ThemeType;
  };
  contentModuleOption?: {
    contentModule?: ContentModuleType;
  };
  foldoutTextOption?: {
    foldoutText?: string;
  };
}

export interface FalconContent extends Partial<ContentFragment> {
  __type: 'aem-headless';
  fields: {
    buttonCaption?: string;
    contentModules?: ContentFragment[];
    /**
     * @deprecated Use contentModules instead
     */
    contentModule?: ContentFragment;
    theme?: ThemeType;
  };
}

export interface AppContent {
  buttonCaption?: string;
  contentModules?: ContentModuleType[];
  theme: ThemeType;
}
