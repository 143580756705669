import React, { useContext } from 'react';
import { FeatureAppLoader, FeatureAppLoaderProps } from '@feature-hub/react';
import { Context } from '../../Context';
import { debug } from '../../utils/debugging';
import { EditorialColumnsFACE as FAModularEditorialContent } from '../../../types-fa-editorial-columns';
import { ContentVersionContext } from '../../ContentVersionContext';
import { beforeCreate } from '../../utils/beforeCreate';

const FA_NAME = 'fa-editorial-columns';
const FA_VERSION = 'v1.4.4';
const FA_BASE_URL = `https://${FA_NAME}.cdn.prod.collab.apps.one.audi/${FA_VERSION}`;
const FA_SRC = `${FA_BASE_URL}/fh/app.js`;
const FA_SERVER_SRC = `${FA_BASE_URL}/fh/app.node.js`;

interface ContentModularEditorialProps {
  content: FAModularEditorialContent;
}

export const ContentEditorialColumns: React.FC<ContentModularEditorialProps> = ({ content }) => {
  const { contentVersion } = useContext(ContentVersionContext);
  const { featureAppEnv } = useContext(Context);
  const featureAppId = `${featureAppEnv.featureAppId}_loaded_${FA_NAME}_v${contentVersion}`;

  const featureAppLoaderAttributes: FeatureAppLoaderProps = {
    featureAppId,
    featureAppName: FA_NAME,
    serverSrc: FA_SERVER_SRC,
    src: FA_SRC,
    beforeCreate: beforeCreate({
      ...content,
    }),
  };

  debug('loading via <FeatureAppLoader>', featureAppLoaderAttributes);

  return <FeatureAppLoader key={`content-v${contentVersion}`} {...featureAppLoaderAttributes} />;
};
