import React from 'react';
import styled from 'styled-components';

interface CancelIconProps {
  isExpanded: boolean;
  transitionDuration: number;
}

const LARGE_SIZE = 24;
const SMALL_SIZE = 16;
const LARGE_SIZE_STROKE_WIDTH = 1;
const SMALL_SIZE_STROKE_WIDTH = 1.6;

const SVG = styled.svg<CancelIconProps>`
  height: ${(props) => (props.isExpanded ? SMALL_SIZE : LARGE_SIZE)}px;
  width: ${(props) => (props.isExpanded ? SMALL_SIZE : LARGE_SIZE)}px;
  stroke-width: ${(props) =>
    props.isExpanded ? SMALL_SIZE_STROKE_WIDTH : LARGE_SIZE_STROKE_WIDTH}px;
  transition: all ${(props) => props.transitionDuration}ms ease-in-out;
`;

const G = styled.g`
  fill: none;
  fill-rule: evenodd;
`;

const Path = styled.path`
  fill-rule: nonezero;
`;

const CancelSVGSmall: React.FC<CancelIconProps> = ({
  isExpanded,
  transitionDuration,
}: CancelIconProps) => {
  return (
    <SVG
      data-testid="cancel-svg"
      viewBox="0 0 24 24"
      isExpanded={isExpanded}
      transitionDuration={transitionDuration}
    >
      <G>
        <Path
          d="m12.17 12.88-4.81 4.81-.71-.7 4.81-4.82-4.81-4.81.7-.71 4.82 4.81 4.81-4.81.71.7-4.81 4.82 4.81 4.81-.7.71-4.82-4.81Z"
          stroke="currentColor"
          fill="none"
          fill-rule="evenodd"
        />
      </G>
    </SVG>
  );
};

export default CancelSVGSmall;
