import React, { useState, useContext } from 'react';
import styled from 'styled-components';

import { ThemeProvider, audiDarkTheme, audiLightTheme, Theme, Text } from '@audi/audi-ui-react';
import DesignTokens from '@audi/audi-ui-design-tokens';
import { UeElement } from '@oneaudi/falcon-tools';
import { renderTextWithFootnotesReferencesV2 } from '@oneaudi/feature-app-utils';

import { FA_LOCATOR_ID, AppContent } from '../../../types';
import { Context } from '../../Context';
import { isDarkTheme } from '../../utils/utils';
import { useTracking } from '../../utils/useTracking';
import FoldoutButton from './FoldoutButton';
import { FoldoutContentContainer } from './FoldoutContentContainer';

const TRANSITION_DURATION = 800;

const Container = styled.div`
  background-color: ${({ theme }: { theme: Theme }) =>
    isDarkTheme(theme) ? DesignTokens.COLOR_BASE_BRAND_BLACK : DesignTokens.COLOR_BASE_BRAND_WHITE};
`;

interface ButtonContainerProps {
  isExpanded: boolean;
  transitionDuration: number;
}

const ButtonContainer = styled.div<ButtonContainerProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  position: sticky;
  top: 0;
  background-color: ${({ theme }: { theme: Theme }) =>
    isDarkTheme(theme) ? DesignTokens.COLOR_BASE_BRAND_BLACK : DesignTokens.COLOR_BASE_BRAND_WHITE};
  z-index: 1;
  @media (min-width: ${DesignTokens.BREAKPOINT_XS}px) {
    padding-top: var(
      ${({ theme, isExpanded }: { theme: Theme; isExpanded: boolean }) =>
        isExpanded ? theme.responsive?.spacing.xxs : theme.responsive?.spacing.xs}
    );
    padding-bottom: var(
      ${({ theme, isExpanded }: { theme: Theme; isExpanded: boolean }) =>
        isExpanded ? 0 : theme.responsive?.spacing.xs}
    );
  }
  @media (min-width: ${DesignTokens.BREAKPOINT_M}px) {
    padding-top: var(
      ${({ theme, isExpanded }: { theme: Theme; isExpanded: boolean }) =>
        isExpanded ? theme.responsive?.spacing.s : theme.responsive?.spacing.m}
    );
    padding-bottom: var(
      ${({ theme, isExpanded }: { theme: Theme; isExpanded: boolean }) =>
        isExpanded ? theme.responsive?.spacing.xs : theme.responsive?.spacing.m}
    );
  }

  transition: padding ${(props) => props.transitionDuration}ms ease-in-out;
`;

interface FoldoutTextProps {
  isExpanded: boolean;
  transitionDuration: number;
}
const FoldoutText = styled(Text)<FoldoutTextProps>`
  margin-top: var(
    ${({ theme, isExpanded }: { theme: Theme; isExpanded: boolean }) =>
      isExpanded ? 0 : theme.responsive?.spacing.xxs}
  );

  transition: margin-top ${(props) => props.transitionDuration}ms ease-in-out;
`;

export const Foldout: React.FC<AppContent> = (props) => {
  const { theme, buttonCaption, contentModules } = props;
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [scrollPosition, setScrollPosition] = useState(0);
  const { isEditRenderMode } = useContext(Context);
  const buttonCaptionFithFootnoteReferences = renderTextWithFootnotesReferencesV2(
    buttonCaption || '',
  );

  const tracking = useTracking({
    implementer: 2,
  });

  React.useEffect(() => {
    tracking.sendReadyEvent();
  }, []);

  const handleButtonClick = () => {
    if (isExpanded) {
      window.scrollTo({
        top: scrollPosition,
        behavior: 'smooth',
      });
      setScrollPosition(0);
    } else {
      setScrollPosition(window.scrollY);
    }
    tracking.sendClickEvent(buttonCaption || '', isExpanded);
    setIsExpanded(!isExpanded);
  };

  return (
    <ThemeProvider theme={theme === 'Dark' ? audiDarkTheme : audiLightTheme}>
      <Container data-test-id={`${FA_LOCATOR_ID}`}>
        {(contentModules || isEditRenderMode) && (
          <>
            <ButtonContainer isExpanded={isExpanded} transitionDuration={TRANSITION_DURATION}>
              <FoldoutButton
                isExpanded={isExpanded}
                onClick={handleButtonClick}
                aria-labelledby="foldout-text"
                transitionDuration={TRANSITION_DURATION}
              />
              {(buttonCaptionFithFootnoteReferences || isEditRenderMode) && (
                <FoldoutText
                  transitionDuration={TRANSITION_DURATION}
                  isExpanded={isExpanded}
                  variant="copy1"
                  weight="bold"
                  id="foldout-text"
                >
                  <UeElement type="text" property="buttonCaption" label="Button Caption">
                    {buttonCaptionFithFootnoteReferences ||
                      (isEditRenderMode ? 'Please enter button caption here' : '')}
                  </UeElement>
                </FoldoutText>
              )}
            </ButtonContainer>
            <FoldoutContentContainer
              contentModules={contentModules}
              isExpanded={isExpanded}
              transitionDuration={TRANSITION_DURATION}
            />
          </>
        )}
      </Container>
    </ThemeProvider>
  );
};
