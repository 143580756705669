import React from 'react';
import { Theme } from '@audi/audi-ui-react';
import DesignTokens from '@audi/audi-ui-design-tokens';
import styled from 'styled-components';
import { isDarkTheme } from '../../utils/utils';
import CancelSVGSmall from './CancelSVGSmall';

interface FoldoutButtonProps {
  isExpanded: boolean;
  onClick: () => void;
  transitionDuration: number;
}

const Button = styled.button<FoldoutButtonProps>`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: none;
  box-shadow: none;
  padding: var(
    ${({ theme, isExpanded }: { theme: Theme; isExpanded: boolean }) =>
      isExpanded ? theme.responsive?.spacing.xxs : theme.responsive?.spacing.s}
  );
  border: 1px solid;
  border-radius: 50%;
  cursor: pointer;
  color: ${({ theme }: { theme: Theme }) =>
    isDarkTheme(theme) ? DesignTokens.COLOR_BASE_BRAND_WHITE : DesignTokens.COLOR_BASE_BRAND_BLACK};
  border-color: ${({ theme }: { theme: Theme }) =>
    isDarkTheme(theme) ? DesignTokens.COLOR_BASE_BRAND_WHITE : DesignTokens.COLOR_BASE_BRAND_BLACK};
  transform: ${(props) => (props.isExpanded ? 'rotate(0deg)' : 'rotate(45deg)')};
  transition: all ${(props) => props.transitionDuration}ms ease-in-out;
`;

const FoldoutButton: React.FC<FoldoutButtonProps> = ({
  isExpanded,
  onClick,
  transitionDuration,
}: FoldoutButtonProps) => {
  return (
    <Button
      isExpanded={isExpanded}
      onClick={onClick}
      aria-label={isExpanded ? 'collapse' : 'expand'}
      data-test-id="expand-btn"
      data-testid="expand-btn"
      transitionDuration={transitionDuration}
    >
      <CancelSVGSmall isExpanded={isExpanded} transitionDuration={transitionDuration} />
    </Button>
  );
};

export default FoldoutButton;
