import React, { ReactNode } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  /**
   * Setting a transparent border for the content wrapper is a hack to allow getting the actual
   * height of the content (that we need for animating the height on expanding/collapsing the content)
   * despite the "collapsing margins" problems in the CSS box model (see
   * https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Box_Model/Mastering_margin_collapsing)
   */
  border: 1px solid transparent;
  border-top: none;
`;

interface ContentWrapperProps {
  children: ReactNode;
}

export const ContentWrapper: React.FC<ContentWrapperProps> = ({ children }) => {
  return <Wrapper>{children}</Wrapper>;
};
