import { ContentServiceV1 } from '@oneaudi/content-service';
import { InvalidContentError, isContentFragment } from '@oneaudi/falcon-tools';
import { AppContent, EditorJsonContent, FalconContent } from '../../types';
import { debug } from './debugging';

export const INVALID_CONTENT_ERROR = 'Content is invalid.';

function isFalconContent(content: FalconContent): content is FalconContent {
  // Check here for all required fields and return true, otherwise false
  return Boolean(isContentFragment(content));
}

export function mapContent(contentService: ContentServiceV1): AppContent {
  const content = contentService.getContent() as EditorJsonContent | FalconContent;

  if (content.__type === 'aem-headless') {
    // Perform runtime validation
    if (!isFalconContent(content)) {
      // Throw `InvalidContentError` here if provided content
      // is not valid. This will display as a special error message
      // in the frontend on author environments.
      debug('InvalidContentError/Content is invalid.', content);
      throw new InvalidContentError(INVALID_CONTENT_ERROR);
    }

    // map CF content to internal structure
    return mapFalconContent(content);
  }

  // map FACE content to internal structure
  return mapEditorJsonContent(content);
}

/**
 * Map EditorJsonContent to AppContent
 *
 * @param content EditorJsonContent
 * @returns AppContent
 */
export function mapEditorJsonContent(content: EditorJsonContent): AppContent {
  debug('mapEditorJsonContent', content);

  const mappedContent: AppContent = {
    theme:
      content && content.themeOption && content.themeOption.theme
        ? content.themeOption.theme
        : 'Light',
  };

  if (content && content.foldoutTextOption && content.foldoutTextOption.foldoutText) {
    mappedContent.buttonCaption = content.foldoutTextOption.foldoutText;
  }

  if (content && content.contentModuleOption && content.contentModuleOption.contentModule) {
    mappedContent.contentModules = [content.contentModuleOption.contentModule];
  }

  debug('mappedContent', mappedContent);

  return mappedContent;
}

/**
 * Map FalconContent to AppContent
 *
 * @param content FalconContent
 * @returns AppContent
 */
export function mapFalconContent(content: FalconContent): AppContent {
  debug('mapFalconContent', content);

  const mappedContent: AppContent = {
    theme: content.fields.theme || 'Light',
  };

  if (content.fields.buttonCaption) {
    mappedContent.buttonCaption = content.fields.buttonCaption;
  }

  if (content.fields.contentModule) {
    mappedContent.contentModules = [content.fields.contentModule];
  }

  if (content.fields.contentModules && content.fields.contentModules.length > 0) {
    mappedContent.contentModules = content.fields.contentModules;
  }

  debug('mappedContent', mappedContent);

  return mappedContent;
}
