import { useContext } from 'react';
import { Context } from '../Context';

// Tracking component names should be static and not change if feature app name changes
const COMPONENT_NAME_WITH_DASH = 'foldout-fa';
const COMPONENT_NAME_WITH_SPACE = 'foldout fa';

// Former feature app called "Drawer" hence these event names
export const EXPAND_CLICK_EVENT_NAME = 'drawer open';
export const COLLAPSE_CLICK_EVENT_NAME = 'drawer close';

export interface Tracking {
  sendClickEvent(buttonLabel: string, isExpanded: boolean): void;
  sendReadyEvent(): void;
}

export function useTracking(attributes?: {
  version?: string;
  implementer?: number;
  [key: string]: unknown;
}): Tracking {
  const { trackingService } = useContext(Context);
  const defaultComponentAdditions = {
    componentInfo: {
      // not accepted by tracking lib
      // componentID: 'bla',
    },
    attributes: {
      version: __FEATURE_APP_VERSION__,
      ...attributes,
    },
  };

  return {
    sendClickEvent: (buttonLabel: string, isExpanded: boolean) => {
      const clickEventName = isExpanded ? COLLAPSE_CLICK_EVENT_NAME : EXPAND_CLICK_EVENT_NAME;
      if (trackingService) {
        const clickEvent = {
          event: {
            attributes: {
              componentName: COMPONENT_NAME_WITH_DASH,
              elementName: 'icon',
              label: buttonLabel,
              clickID: '',
              pos: '',
              value: '',
            },
            eventInfo: {
              eventAction: 'content',
              eventName: `${COMPONENT_NAME_WITH_SPACE} - ${clickEventName}`,
            },
          },
          componentUpdate: defaultComponentAdditions,
        };
        trackingService.track(clickEvent);
      }
    },
    sendReadyEvent: () => {
      if (trackingService) {
        const readyEvent = {
          event: {
            attributes: {
              componentName: COMPONENT_NAME_WITH_DASH,
              elementName: '',
              label: '',
              clickID: '',
              pos: '',
              targetURL: '',
              value: '',
            },
            eventInfo: {
              eventAction: 'feature_app_ready',
              eventName: `${COMPONENT_NAME_WITH_SPACE} - feature app ready`,
            },
          },
          componentUpdate: defaultComponentAdditions,
        };
        trackingService.track(readyEvent);
      }
    },
  };
}
