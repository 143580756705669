import React, { createContext, PropsWithChildren, useMemo } from 'react';
import { TrackingServiceV2 } from '@oneaudi/audi-tracking-service';
import { RenderModeServiceV1, RenderMode } from '@oneaudi/render-mode-service';
import { debug } from './utils/debugging';

interface FeatureAppEnv {
  featureAppId: string;
  featureAppName?: string;
}

interface ContextState {
  readonly featureAppEnv: FeatureAppEnv;
  readonly trackingService?: TrackingServiceV2;
  readonly renderMode: RenderMode;
  readonly isDefaultRenderMode: boolean;
  readonly isEditRenderMode: boolean;
}

export const Context = createContext<ContextState>({} as ContextState);

export interface ContextProviderProps {
  readonly featureAppEnv: FeatureAppEnv;
  readonly trackingService?: TrackingServiceV2;
  readonly renderModeService?: RenderModeServiceV1;
}

export const ContextProvider = ({
  children,
  featureAppEnv,
  trackingService,
  renderModeService,
}: PropsWithChildren<ContextProviderProps>) => {
  const value = useMemo(() => {
    const renderMode =
      renderModeService && renderModeService.getRenderMode() === RenderMode.EDIT
        ? RenderMode.EDIT
        : RenderMode.DEFAULT;

    debug(
      `renderMode == ${renderMode} == ${renderMode === RenderMode.DEFAULT ? 'DEFAULT' : 'EDIT'}`,
    );

    return {
      featureAppEnv,
      trackingService,
      renderMode,
      isDefaultRenderMode: renderMode === RenderMode.DEFAULT,
      isEditRenderMode: renderMode === RenderMode.EDIT,
    };
  }, [featureAppEnv, trackingService, renderModeService]);
  return <Context.Provider value={value}>{children}</Context.Provider>;
};
