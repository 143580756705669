import { FeatureAppEnvironment, FeatureServices } from '@feature-hub/core';
import { ContentServiceV1 } from '@oneaudi/content-service';
import { debug } from './debugging';

const CONTENT_SERVICE = 'audi-content-service';

export interface FeatureServiceDependencies extends FeatureServices {
  readonly 'audi-content-service'?: ContentServiceV1;
}

export const beforeCreate =
  (contentConfig?: Record<string, unknown>) =>
  (env: FeatureAppEnvironment<FeatureServiceDependencies, unknown>): void => {
    const { featureServices } = env;

    const faContentService = featureServices?.[CONTENT_SERVICE] as ContentServiceV1;
    if (faContentService && contentConfig) {
      faContentService.setContent(contentConfig);
      debug(`Child fa content-service content set`, contentConfig);
    } else {
      debug(`Child fa content-service not found (${CONTENT_SERVICE})`, featureServices);
    }
  };
