import React, { useContext } from 'react';
import { FeatureAppLoader, FeatureAppLoaderProps } from '@feature-hub/react';
import { ContentFragment } from '@oneaudi/falcon-tools';
import { Context } from '../../Context';
import { debug } from '../../utils/debugging';
import { ContentVersionContext } from '../../ContentVersionContext';
import { beforeCreate } from '../../utils/beforeCreate';

interface ContentBasicTeaserProps {
  contentModules: ContentFragment[];
}

export const ContentCF: React.FC<ContentBasicTeaserProps> = ({ contentModules }) => {
  const { contentVersion } = useContext(ContentVersionContext);
  const { featureAppEnv } = useContext(Context);

  return contentModules.map((content, index) => {
    const featureAppName = content?.model?.title || 'CF';
    const featureAppId = `${featureAppEnv.featureAppId}_loaded_${featureAppName}_v${contentVersion}_#${index}`;
    const baseUrl = content.model?.metadata?.baseUrl;
    const serverSrc = `${baseUrl}/${content.model?.metadata?.serverSrc}`;
    const src = `${baseUrl}/${content.model?.metadata?.src}`;

    const featureAppLoaderAttributes: FeatureAppLoaderProps = {
      featureAppId,
      featureAppName,
      serverSrc,
      src,
      beforeCreate: beforeCreate({
        ...content,
        __type: 'aem-headless', // explicitly set the type as is is missing in child content fragments
      }),
    };

    debug('loading via <FeatureAppLoader>', featureAppLoaderAttributes);

    return (
      <FeatureAppLoader
        // eslint-disable-next-line react/no-array-index-key
        key={`content-#${index}-v${contentVersion}`}
        {...featureAppLoaderAttributes}
      />
    );
  });
};
