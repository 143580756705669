/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */

import { ContentModuleType, ThemeType } from '../../types';

/**
 * Retrieve theme from a content json
 */
export const getContentTheme = (contentModules?: ContentModuleType[]): ThemeType => {
  const content: any = contentModules && contentModules.length > 0 ? contentModules[0] : undefined;

  // mostly Bonfire Nemo FAs & Editorial Columns
  if (
    typeof content !== 'undefined' &&
    typeof content.themeOption !== 'undefined' &&
    typeof content.themeOption.theme !== 'undefined' &&
    content.themeOption.theme.toLowerCase() === 'dark'
  ) {
    return 'Dark';
  }

  // mostly Bonfire headless FAs that were migrated using auto property naming
  if (
    typeof content !== 'undefined' &&
    typeof content.themeOption_theme !== 'undefined' &&
    content.themeOption_theme.toLowerCase() === 'dark'
  ) {
    return 'Dark';
  }

  // Basic Teaser and other Tron FAs
  if (
    typeof content !== 'undefined' &&
    typeof content.themeColor !== 'undefined' &&
    content.themeColor.toLowerCase() === 'dark'
  ) {
    return 'Dark';
  }

  // no theme detected, light kis default
  return 'Light';
};
