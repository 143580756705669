/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React, { useRef, useState, useContext } from 'react';
import styled from 'styled-components';

import { ContentFragment, UeElement, isContentFragment } from '@oneaudi/falcon-tools';
import { Text } from '@audi/audi-ui-react';
import DesignTokens from '@audi/audi-ui-design-tokens';

import { Context } from '../../Context';
import { ContentModularEditorial } from './ContentModularEditorial';
import { ContentBasicTeaser } from './ContentBasicTeaser';
import { ContentEditorialColumns } from './ContentEditorialColumns';
import { ContentCF } from './ContentCF';
import {
  ContentModuleType,
  CONTENT_TYPE_FA_MODULAR_EDITORIAL,
  CONTENT_TYPE_FA_BASIC_TEASER,
  CONTENT_TYPE_FA_EDITORIAL_COLUMNS,
  ThemeType,
} from '../../../types';
import { debug } from '../../utils/debugging';
import { ContentWrapper } from './ContentWrapper';
import { getContentTheme } from '../../utils/getContentTheme';

export interface FoldoutContentContainerProps {
  contentModules?: ContentModuleType[];
  isExpanded: boolean;
  transitionDuration: number;
}

export interface ContainerProps {
  isExpanded: boolean;
  contentHeight: number;
  transitionDuration: number;
  contentTheme: ThemeType;
}

const Container = styled.div<ContainerProps>`
  --panel-height: ${(props) => (props.isExpanded ? props.contentHeight : 0)}px;
  box-sizing: border-box;
  height: var(--panel-height);
  width: 100%;
  visibility: ${(props) => (props.isExpanded ? 'visible' : 'hidden')};
  display: flex;
  flex-direction: column;
  align-items: center;
  transition:
    height ${(props) => props.transitionDuration}ms ease-in-out,
    visibility ${(props) => props.transitionDuration}ms ease-in-out;
  overflow: hidden;
  background-color: ${(props) =>
    props.contentTheme === 'Dark'
      ? DesignTokens.COLOR_BASE_BRAND_BLACK
      : DesignTokens.COLOR_BASE_BRAND_WHITE};
`;

export const FoldoutContentContainer: React.FC<FoldoutContentContainerProps> = ({
  contentModules,
  isExpanded,
  transitionDuration,
}: FoldoutContentContainerProps) => {
  const contentRef = useRef<HTMLDivElement | null>(null);
  const [contentHeight, setContentHeight] = useState<number>(0);
  const { isEditRenderMode } = useContext(Context);

  React.useEffect(() => {
    if (isExpanded && contentRef && contentRef.current) {
      setContentHeight(contentRef.current.clientHeight);
    }
  }, [contentRef, isExpanded]);

  function renderContentModule() {
    if (
      contentModules &&
      contentModules.length > 0 &&
      contentModules[0].contentType === CONTENT_TYPE_FA_MODULAR_EDITORIAL
    ) {
      return <ContentModularEditorial content={contentModules[0]} />;
    }

    if (
      contentModules &&
      contentModules.length > 0 &&
      contentModules[0].contentType === CONTENT_TYPE_FA_BASIC_TEASER
    ) {
      return <ContentBasicTeaser content={contentModules[0]} />;
    }

    if (
      contentModules &&
      contentModules.length > 0 &&
      contentModules[0].contentType === CONTENT_TYPE_FA_EDITORIAL_COLUMNS
    ) {
      return <ContentEditorialColumns content={contentModules[0]} />;
    }

    if (contentModules && contentModules.length > 0 && isContentFragment(contentModules[0])) {
      return <ContentCF contentModules={contentModules as ContentFragment[]} />;
    }

    debug(
      `Unknown contentmodule: ${contentModules && contentModules.length > 0 ? `contentType == ${contentModules[0].contentType}` : 'contentModules == undefined'}`,
    );

    if (isEditRenderMode) {
      return (
        <div style={{ textAlign: 'center' }}>
          <Text spaceStackStart="m" spaceStackEnd="m">
            Please add content module here
          </Text>
        </div>
      );
    }

    return null;
  }

  return (
    <UeElement type="container" property="contentModule" label="Content Module">
      {(ueProps) => (
        <Container
          isExpanded={isExpanded}
          contentHeight={contentHeight}
          data-test-id="foldout-content-container"
          transitionDuration={transitionDuration}
          contentTheme={getContentTheme(contentModules)}
          {...ueProps}
        >
          <div ref={contentRef} style={{ width: '100%' }}>
            <ContentWrapper>{renderContentModule()}</ContentWrapper>
          </div>
        </Container>
      )}
    </UeElement>
  );
};
