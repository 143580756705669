/**
 * This is the starting point of your application.
 * Feature Hub Apps will use this file to bootstrap the app.
 */
import React from 'react';
import { ContentServiceV1 } from '@oneaudi/content-service';

import { AppContent } from '../types';
import { Foldout } from './components/foldout/Foldout';
import { mapContent } from './utils/contentMapping';
import { ContentVersionContext } from './ContentVersionContext';

interface FeatureAppProps {
  contentService: ContentServiceV1;
}

const FeatureApp: React.FC<FeatureAppProps> = ({ contentService }: FeatureAppProps) => {
  const { contentVersion, setContentVersion } = React.useContext(ContentVersionContext);

  const [content, setContent] = React.useState<AppContent | undefined>(mapContent(contentService));

  React.useEffect(() => {
    const listener = () => {
      setContent(mapContent(contentService));
      setContentVersion(contentVersion + 1);
    };
    contentService.onContentChange(listener);

    return () => {
      contentService.removeOnContentChange(listener);
    };
  }, [contentService, contentVersion]);

  return content ? <Foldout {...content} /> : null;
};

export default FeatureApp;
